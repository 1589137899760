body {
    font-family: 'MecherleLegal', sans-serif;
}

/* oneX override */
.-oneX-page-loader {
    display: inline;
}

.oneX-col-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* size classess */
.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.ml-20 {
    margin-left: 20px !important;
}

.w700 {
    width: 900px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-30 {
    padding-top: 30px;
}

.text-center {
    text-align: center;
}

.hidden {
    display: none;
}

input.read0 {
    outline: none;
    border: 0px !important;
    width: 95px;
    text-align: right;
}

input.read0:focus {
    border: 0px !important;
    outline: none;
}

.gray-01 {
    color: #767676;
}

.progress-bar {
    height: 65px !important;
}

.address legend {
    padding-top: 20px;
}

.address ul {
    margin: 0;
    padding: 0;
}

.address ul li {
    list-style: none;
    color: #767676;
    line-height: 1.6;
}

.upload-button-panel {
    display: flex;
    align-items: center;
}

.upload-file-icon {
    height: 96px;
    width: 96px;
}

.delete-file-icon {
    height: 50px;
    width: 50px;
}


.upload-file-table {
    margin-top: 10px;
    box-shadow: inset 0 -1px 0 0 #767676, inset -1px 0 0 #f4f3f3, inset 1px 0 0 #f4f3f3,
    inset 0 1px 0 0 #f4f3f3;
    padding-left: 20px;
    padding-right: 20px;
}

.upload-file-table label {
    color: #767676;
    font-family: 'MecherleSans-Regular';
    padding: 14px 15px 13px 5px;
    text-align: left;
    display: block;
}

.upload-file-table-tr {
    box-shadow: inset 0 1px 0 0 #f4f3f3;
}

.upload-file-table-th label {
    color: #403c3c;
    font-family: 'MecherleSans-SemiBold';
    padding: 14px 15px 13px 5px;
}

.upload-file-delete {
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-file-delete button {
    padding: 0 0 0 10px;
    color: #d62311;
    background-color: transparent;
    border: 0;
}

.upload-file-delete button:hover {
    border-color: #fff;
    box-shadow: none;
    color: #a11b13;
}

.upload-file-delete button:active {
    box-shadow: none;
}

.upload-file-delete button div {
    display: flex;
}

.upload-file-delete-icon {
    height: 24px;
    width: 24px;
}

.upload-file-delete-title {
    margin-top: 4px;
}

.upload-comment-info {
    color: #2d2929;
    font-family: 'MecherleSans-Regular', Arial;
    color: #767676;
    font-size: 14px;
}

.purchase-date-input-container {
    margin-top: 16px;
    margin-bottom: 16px;
  }

/* .faTrashCan {
    color:#D62311;  
    border: none;
    background-color: white;
     
} */

.upload-comment-info ul {
    margin-top: 0;
    padding: 0;
}

.upload-comment-info ul li {
    list-style: none;
}

aside {
    margin-top: 60px;
}

aside .-oneX-col-1 {
    max-width: 60px;
}

aside p {
    margin-bottom: 0px;
}

aside ul {
    margin-bottom: 0px;
}

#b2b-footer {
    margin-top: 150px;
}

.tableContent {
    font-size: 14px;
    border-bottom: 1px solid #efefef;
}

/*.c {*/
/*    table-layout: auto;*/
/*    width: 100%;*/
/*}*/

.tableHeading {
    border-bottom: 1px solid #e4e3e3;
    font-family: 'MecherleSans-SemiBold';
    font-size: 14px;
}

.tableFixHead {
    overflow: auto;
    /*height: 400px;*/
    max-height: 400px;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Just common table stuff. Really. */
table {
    /*border-collapse: collapse;*/
    text-align: left;
    width: 100%;
    max-height: 200px;
    overflow: auto;
}

th,
td {
    padding: 10px;
}

th {
    border-bottom: 1px solid #0e0000;
    font-family: 'MecherleSans-SemiBold';
    font-size: 14px;
    background: white;
}
